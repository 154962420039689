import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { RunStatus } from "../../interfaces/enums";
import { useMarketMoveMetaDataMethods } from "../../store/formStore";
import useMarketMoveStore from "../../store/marketMoveStore";

// Load timezone and UTC plugins for dayjs
dayjs.extend(utc);
dayjs.extend(timezone);

const CST_TIME_ZONE = "America/Chicago"; // CST timezone
const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:00";

const ZonedDateTimePicker = ({ name, onAccept }: { name: "startDate" | "endDate", onAccept?: (value: Date, name: "startDate" | "endDate")=>void }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);  //Tracking open state because we only want to handle on change if the dialog is closed. (Check for date range warning if user manually types a date)
  const [minDateTime, setMinDateTime] = useState(dayjs()); // Default to now
  const { setValue, watch, formState: {errors, defaultValues} } = useMarketMoveMetaDataMethods();
  const { disableEditing } = useMarketMoveStore();
  const detectTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const formDate = watch(name);
  const formMMId = watch("marketMoveId");
  const runStatus = watch("runStatus");

  const valueDayjs = dayjs(formDate).tz(CST_TIME_ZONE);
  const now = dayjs();
  const error = errors[name];

  const setDateTimeBasedOnTimeZone = (valueDayjs: dayjs.Dayjs, now: dayjs.Dayjs) => {
    const userTimeZone = detectTimeZone();

    if (userTimeZone !== CST_TIME_ZONE) {
      const cstDate = dayjs.tz(valueDayjs, CST_TIME_ZONE);
      setSelectedDate(cstDate);
      setMinDateTime(now.tz(CST_TIME_ZONE));
    } else {
      console.debug("[setDateTimeBasedOnTimeZone] - User is in CST timezone");
      setSelectedDate(valueDayjs);
      setMinDateTime(now);
    }


  };

  // Set the default date-time to CST if the user's time zone is different
  useEffect(() => {
    setDateTimeBasedOnTimeZone(valueDayjs, now);
  }, [watch]);

  useEffect(() => {
    if (formMMId) {
      setSelectedDate(dayjs(formDate));
    } else {
      setSelectedDate(dayjs(defaultValues[name]));
    }
    // Adjust minDateTime if runStatus is INPROGRESS and name is "endDate"
    if (runStatus === RunStatus.INPROGRESS && name === "endDate") {
      const minEndDateTime = now.tz(CST_TIME_ZONE).add(15, 'minutes');
      setMinDateTime(minEndDateTime);
    }
  }, [formMMId]);

  function useDebouncedHandler(valueToDebounce, delay = 1000) {
    const [debouncedValue, setDebouncedValue] = useState(valueToDebounce);
    useEffect(() => {
      console.log("valueToDebounce changed.");
      const handler = setTimeout(() => {
        setDebouncedValue(dayjs(valueToDebounce));
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [valueToDebounce, delay]);

    return debouncedValue;
  }

  const [selDate, setSelDate] = useState(null);
  const debouncedDate = useDebouncedHandler(selDate);

  useEffect(() => {
    const isValidDate = dayjs(debouncedDate).isValid();
    if(isValidDate && !isOpen && debouncedDate != null) {
      onAccept(dayjs(debouncedDate).toDate(), name);
    }
  }, [debouncedDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label="Select Date and Time (CST)"
        value={selectedDate}
        ampm={false}
        onAccept={(newValue: dayjs.Dayjs) => {
          onAccept(newValue.toDate(), name);
        }}
        onChange={(newValue: dayjs.Dayjs) => {
          console.debug(`[ZonedDateTimePicker] - New value: ${newValue}`);
          console.debug(`[ZonedDateTimePicker] - Formatted value: ${newValue.format(DATE_TIME_FORMAT)}`);
          setValue(name, newValue.format(DATE_TIME_FORMAT), {
            shouldValidate: true,
            shouldDirty: true,
          });

          setSelDate(newValue.format(DATE_TIME_FORMAT)); // Used for debouncing only user changes
        }}
        onOpen={() => {setIsOpen(true);}}
        onClose={() => {setIsOpen(false);}}
        minDateTime={minDateTime} // Disable past dates and times
        slotProps={{
          textField: {
            fullWidth: true,
            error: !!error,
            helperText: error?.message,
          },
        }}
        disabled={disableEditing && !(runStatus === RunStatus.INPROGRESS && name === "endDate")}
      />
    </LocalizationProvider>
  );
};

export default ZonedDateTimePicker;
