import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useMarketMoveCancel } from "../../../../hooks/useMarketMoveCancel";
import useMarketMoveStore from "../../../../store/marketMoveStore";
import CancelationTable from "../CancelationTable";
import StoreListTable from "./StoreListTable";
import { useStoreList } from "./useStoreList";
import useLocalStorage from "../../../../hooks/useLocalStorage";

interface StoreListDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
}
const StyledDialogActions = styled(DialogActions)({
  padding: "0px 0px",
  justifyContent: "flex-end",
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StoreListDialog: React.FC<StoreListDialogProps> = ({
  open,
  setOpen,
  onClose,
}) => {
  const {
    handleToggle,
    workingElasticData,
    storeList,
    showAllStores,
    setShowAllStores,
    resetStoreList,
    finalizeSelection,
  } = useStoreList();

  const { enqueueSnackbar } = useSnackbar();
  const [cancelMarketMoveDialog, setCancelMarketMoveDialog] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStoreIds, setSelectedStoreIds] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useLocalStorage<Record<string, boolean>>("storeListTable_RowSelection", {});
  const totalCount = storeList.length;
  const { disableEditing } = useMarketMoveStore();
  const selectedCount =
    storeList?.filter((store) => store.includeFlag).length || 0;

  const { cancelMarketMove } = useMarketMoveCancel({
    onSuccess: () => {
      setCancelMarketMoveDialog(false);
      setPreviewDialogOpen(false);
    },
  });

  const getButtonText = () => {
    if (selectedCount === 0) return `${totalCount} Stores`;
    return `Store List (${selectedCount} / ${totalCount})`;
  };

  const getTooltipText = () => {
    if (selectedCount === 0)
      return "There are no stores selected but there are stores available.";
    return `${selectedCount} out of ${totalCount} available stores selected`;
  };

  const handleOk = async () => {
    onClose();
    setOpen(false);
  };

  const handleCancel = async () => {
    if (showAllStores) {
      setShowAllStores(false);
    } else {
      resetStoreList();
      setOpen(false);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCancelWholeMarketMove = () => {
    console.log("Canceling whole market move");
    setCancelMarketMoveDialog(true);
    handleMenuClose();
  };

  const handleCancelOneStore = () => {
    console.log("Canceling one store from market move");
    setPreviewDialogOpen(true);
    handleMenuClose();
  };

  const handleCancelDialogClose = () => {
    setCancelMarketMoveDialog(false);
  };

  const handleSelectionChange = useCallback((newSelectedStoreIds: string[]) => {
    setSelectedStoreIds(newSelectedStoreIds);
  }, []);

  useEffect(() => {
    console.log("Selected store IDs:", selectedStoreIds);
  }, [selectedStoreIds]);

  const handleConfirmCancel = async () => {
    enqueueSnackbar(
      "Request sent to Cancel Whole Market Move... Please Wait.",
      { variant: "info" }
    );
    await cancelMarketMove("CANCELMM");
  };

  const handlePreviewSubmit = async () => {
    enqueueSnackbar(
      "Request sent to remove selected stores from Market Move... Please wait.",
      { variant: "info" }
    );
    await cancelMarketMove("CANCELSTORES", selectedStoreIds);
  };

  const handlePreviewDialogClose = () => {
    setPreviewDialogOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Tooltip title={getTooltipText()}>
          <span>
            <Button
              variant="contained"
              onClick={() => setOpen(true)}
              color={selectedCount === 0 ? "secondary" : "primary"}
            >
              {getButtonText()}
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Market Move Cancel Options">
          <span>
            <IconButton
              onMouseEnter={handleMenuOpen}
              disabled={!disableEditing}
            >
              <MoreVertIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{ onMouseLeave: handleMenuClose }}
      >
        <MenuItem onClick={handleCancelWholeMarketMove}>
          Cancel Whole Market Move
        </MenuItem>
        <MenuItem onClick={handleCancelOneStore}>
          Cancel Store(s) from Market Move
        </MenuItem>
      </Menu>

      <BootstrapDialog open={open} onClose={() => {}} fullWidth maxWidth="lg">
        <DialogTitle>Selected Stores</DialogTitle>
        <DialogContent>
          <StoreListTable
            workingElasticData={workingElasticData}
            storeList={storeList}
            showAllStores={showAllStores}
            handleToggle={handleToggle}
            setShowAllStores={setShowAllStores}
            finalizeSelection={finalizeSelection}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        </DialogContent>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogActions>
          {/*  <Tooltip title="Reset Changes">*/}
          {/*    <span>*/}
          {/*      <IconButton onClick={resetStoreList}>*/}
          {/*        <RestoreIcon />*/}
          {/*      </IconButton>*/}
          {/*    </span>*/}
          {/*  </Tooltip>*/}
          <Button
            onClick={() => {
              handleCancel();
            }}
            color="error"
            variant={"contained"}
          >
            {showAllStores ? "Exit Selection" : "Cancel"}
          </Button>
          <Button
            onClick={() => {
              if (showAllStores) {
                setShowAllStores(false);
                finalizeSelection(
                  Object.entries(rowSelection)
                    .filter(([_, selected]) => selected)
                    .map(([key, value]) => {
                      return { storeId: key, selected: value };
                    })
                );
              } else {
                handleOk();
              }
            }}
            color="primary"
            variant={"contained"}
          >
            {showAllStores ? "Finalize Selection" : "Ok"}
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <Dialog
        open={cancelMarketMoveDialog}
        onClose={handleCancelDialogClose}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle id="cancel-dialog-title">
          Confirm Cancellation of Whole Market Move
        </DialogTitle>
        <DialogContent>
          <DialogContent id="cancel-dialog-description">
            Are you sure you want to cancel the entire market move? This action
            cannot be undone.
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmCancel}
            color="primary"
            variant={"contained"}
          >
            Yes, Cancel
          </Button>
          <Button
            onClick={handleCancelDialogClose}
            color="error"
            variant={"contained"}
          >
            No, Do Not Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={previewDialogOpen}
        onClose={handlePreviewDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Select Included Stores to Remove</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <CancelationTable onSelectionChange={handleSelectionChange} />
            </Grid>
            <Grid item>
              <StyledDialogActions>
                <Button
                  onClick={handlePreviewSubmit}
                  color="primary"
                  variant="contained"
                >
                  Remove Stores
                </Button>
                <Button
                  onClick={handlePreviewDialogClose}
                  color="error"
                  variant="contained"
                >
                  Discard Changes
                </Button>
              </StyledDialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StoreListDialog;
