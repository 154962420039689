import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { IconButton, Tooltip } from "@mui/material";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { IStore } from "../../../../interfaces/IStore";
import { MarketMoveStoreListSchema } from "../../../../schema/schemas";
import { useMarketMoveRunMethods } from "../../../../store/formStore";
import { listViewColumnDef } from "../../columns";
import StoreListTableToolbar from "./StoreListTableToolbar";
import { RunStatus } from "../../../../interfaces/enums";
import useLocalStorage from "../../../../hooks/useLocalStorage";

type StoreWithInclusion = IStore & { includeFlag: boolean, flag: string };

interface StoreListTableProps {
  workingElasticData: StoreWithInclusion[];
  storeList: MarketMoveStoreListSchema[];
  showAllStores: boolean;
  handleToggle: (storeId?: string) => void;
  setShowAllStores: (showAllStores: boolean) => void;
  finalizeSelection: (selectedStores: { storeId: string, selected: boolean }[]) => void;
  rowSelection: Record<string, boolean>;
  setRowSelection: (rowSelection: Record<string, boolean>) => void;
}

const flagColorMap: Record<string, string> = {
  REMOVE: '#ffebee',  // light red
  INSERT: '#e8f5e9',  // light green
  UPDATE: '#fff3e0',  // light orange
  NOUPDATE: 'inherit' // default
};

const StoreListTable: React.FC<StoreListTableProps> = ({
  workingElasticData,
  storeList,
  showAllStores,
  handleToggle,
  setShowAllStores,
  finalizeSelection,
  rowSelection,
  setRowSelection,
}) => {
  const [columnVisibility, setColumnVisibility] = useLocalStorage<Record<string, boolean>>("storeListTable_ColumnVisibility", {});

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "action", desc: false },
  ]);

  const { watch: watchMMRValues } = useMarketMoveRunMethods();

  const runStatus = watchMMRValues("marketMoveRun.runStatus");

  const clearSelection = () => setRowSelection({});

  const selectAllStores = () => {
    setRowSelection(
      storeList.reduce((acc, store) => {
        const selected = store.flag !== "REMOVE";
        acc[store.storeId] = selected;
        return acc;
      }, {} as Record<string, boolean>)
    );
  };

  useEffect(() => {
    showAllStores ? selectAllStores() : clearSelection();
    setColumnVisibility(prev => ({
      ...prev,
      action: !showAllStores
    }));
  }, [showAllStores]);


  const columns = useMemo<MRT_ColumnDef<StoreWithInclusion>[]>(
    () => [
      {
        accessorKey: "action",
        header: "Include",
        
        Header: () => (
          <Tooltip title="Include">
            <IconButton size="small" onClick={() => handleToggle()} disabled={runStatus === RunStatus.INPROGRESS}>
              {/* If every store is selected, show a check icon, otherwise show a unchecked icon */}
              {storeList.every((store) => store.includeFlag) ? (
                <CheckCircleOutlineIcon />
              ) : (
                <RadioButtonUncheckedIcon />
              )}
            </IconButton>
          </Tooltip>
        ),
        maxSize: 20,
        Cell: ({ row }) => {
          const include = row.original.includeFlag;
          return (
            <Tooltip title={include ? "Deselect" : "Select"}>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggle(row.original.seiid);
                }}
                disabled={runStatus === RunStatus.INPROGRESS}
              >
                {include ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </IconButton>
            </Tooltip>
          );
        },
        accessorFn: (row: any) => {
          return row.includeFlag;
        },
        sortingFn: (rowA, rowB, columnId) => {
          // First, sort by includeFlag
          const includeFlagCompare =
            (rowB.original.includeFlag === true ? 1 : 0) -
            (rowA.original.includeFlag === true ? 1 : 0);

          if (includeFlagCompare !== 0) return includeFlagCompare;

          // If includeFlag is the same, use the column's sorting
          const aValue = rowA.original[columnId];
          const bValue = rowB.original[columnId];

          if (aValue === bValue) return 0;
          if (aValue > bValue) return 1;
          if (aValue < bValue) return -1;

          return 0;
        },
      },
      ...listViewColumnDef,
    ],
    [storeList]
  );

  const table = useMaterialReactTable<StoreWithInclusion>({
    columns,
    data: workingElasticData,
    columnFilterDisplayMode: "subheader",
    layoutMode: "grid",
    enableBottomToolbar: false,
    enableTopToolbar: true,
    enableDensityToggle: false,
    enablePagination: false,
    enableRowSelection: showAllStores,
    enableMultiSort: true,
    enableRowVirtualization: true,
    enableGlobalFilter: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableFacetedValues: true,
    enableCellActions: false,
    enableStickyHeader: true,
    enableHiding: true,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    initialState: {
      density: "compact",
      sorting: [{ id: "action", desc: false }],
      rowSelection: storeList.reduce((acc, store) => {
        acc[store.storeId] = store.includeFlag;
        return acc;
      }, {} as Record<string, boolean>),
    },
    state: {
      columnVisibility,
      sorting,
      rowSelection,
    },
    defaultColumn: {
      size: 50,
    },
    sortingFns: {},

    getRowId: (originalRow: StoreWithInclusion) => originalRow.seiid,
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
        backgroundColor: flagColorMap[row.original.flag] || 'inherit',
        '&:hover': {
          backgroundColor: flagColorMap[row.original.flag] || 'inherit',
          filter: 'brightness(0.95)'
        }
      }
    }),
    muiTableBodyProps: {
      sx: {
        display: "block",
        overflow: "auto",
      },
    },
    renderTopToolbarCustomActions: () =>
      StoreListTableToolbar({
        runStatus,
        handleToggleAll: handleToggle,
        isExpanded: showAllStores,
        onToggleExpand: () => setShowAllStores(!showAllStores),
        finalizeSelection: () => finalizeSelection(
          Object.entries(rowSelection)
            .map(([key, selected]) => ({ storeId: key, selected: selected }))
        ),
      }),
  });

  return <MaterialReactTable table={table} />;
};

export default StoreListTable;
